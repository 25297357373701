/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';

import Filled from './Filled';
import Outlined from './Outlined';

const Logo = ({ logoStyle, ...rest }) => {
  if (logoStyle === 'outlined') return <Outlined {...rest} />;

  return <Filled {...rest} />;
};

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  colorPrimary: PropTypes.string,
  colorSecondary: PropTypes.string,
  logoStyle: PropTypes.string,
};

Logo.defaultProps = {
  width: 280,
  height: 49,
  colorPrimary: '#0D0440',
  colorSecondary: '#346BF7',
  logoStyle: 'filled',
};

export default Logo;
