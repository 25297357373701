import React from 'react';

import { Background, Info, Icon } from './styles';
import { Button } from '../../components';

const ModalInfo = ({ open, toggleModal }) => (
  <>
    <Background open={open} />
    <Info open={open}>
      <h3>Como validar meu documento?</h3>
      <ul>
        <li>
          <Icon>1</Icon>
          <div>
            Acesse a{' '}
            <a
              href="https://app.contraktor.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              nossa plataforma
            </a>{' '}
            e baixe o pacote dos arquivos.
          </div>
        </li>
        <li>
          <Icon>2</Icon>
          <div>
            Descompacte o pacote dos arquivos e procure pelos arquivos{' '}
            <b>Documento Assinado.pdf</b> ou <b>Documento Original.pdf</b>.
          </div>
        </li>
        <li>
          <Icon>3</Icon>
          <div>
            Arraste um dos arquivos ou faça o upload dos mesmos para o nosso
            validador e aguarde a verificação.
          </div>
        </li>
      </ul>
      <Button onClick={toggleModal}>Ok, entendi</Button>
    </Info>
  </>
);

export default ModalInfo;
