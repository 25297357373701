import React from 'react';

import { Link } from '../../../../components';

import { Wrapper } from './styles';

import { Loading as IconLoading } from '../../../../../Icons';

const Loading = ({ onResetValidation }) => (
  <Wrapper>
    <section>
      <IconLoading color="rgba(0,0,0, 0.1)" />
    </section>
    <p>Validando documento</p>
    <Link role="button" onClick={onResetValidation}>
      Cancelar
    </Link>
  </Wrapper>
);

export default Loading;
