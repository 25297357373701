import React from 'react';
import styled from 'styled-components';

const IconSvg = styled.svg`
  fill: currentColor;
  fill-rule: evenodd;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const Loading = (props) => (
  <IconSvg
    viewBox="0 0 50 50"
    height="1em"
    {...props}
  >
    <path d="M25.3 6.5A18.7 18.7 0 0 0 6.6 25h4c0-8 6.6-14.6 14.7-14.6v-4z">
      <animateTransform attributeName="transform" attributeType="xml" dur="0.6s" from="0 25 25" repeatCount="indefinite" to="360 25 25" type="rotate"/>
    </path>
  </IconSvg>
);

export default Loading;
