/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from 'styled-components';

import Logo from '../Logo';

import GreenLockLabel from '../../assets/icons/iconeSeguro.svg';

const Wrapper = styled.div`
  min-width: 65%;
  margin: 3.2rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SafeEnviromentLabel = styled.div`
  max-width: 10rem;
  background: #64d0b8;
  border-radius: 0.5rem;
  padding: 0.75rem 0.5rem;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  img {
    width: 1rem;
    margin-right: 0.25rem;
  }
`;

const Header = () => (
  <Wrapper>
    <Logo />
    <SafeEnviromentLabel>
      <img src={GreenLockLabel} alt="Ícone ambiente seguro" />
      <span>AMBIENTE SEGURO</span>
    </SafeEnviromentLabel>
  </Wrapper>
);

export default Header;
