import styled from 'styled-components';
import { IoMdBusiness } from 'react-icons/io';

export const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
  color: #353535;
  height: fit-content;
  transition: height 200ms;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Name = styled.span`
  font-weight: bold;
  display: flex;
  align-items: center;

  > span {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
`;

export const BusinessIcon = styled(IoMdBusiness)`
  margin-right: 0.5rem;
`;

export const DetailsButton = styled.button`
  font-size: 10px;
  background-color: ${props => props.theme.lightBackgroundBorder};
  border-radius: 6px;
  border: 0;
  padding: 4px 8px;
  transition: opacity 200ms;

  &:hover {
    opacity: 0.5;
    transition: opacity 200ms;
  }
`;

export const Details = styled.div`
  max-height: ${props => (!props.collapsed ? '21rem' : '0')};
  margin-top: ${props => (!props.collapsed ? '0.5rem' : '0')};
  height: auto;
  overflow: hidden;
  transition: max-height 200ms ease-out;

  display: flex;
  flex-direction: column;
`;
