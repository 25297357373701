import React, { Component } from 'react';

import { parseISO, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import {
  Wrapper,
  Name,
  BusinessIcon,
  Header,
  Details,
  DetailsButton,
} from './styles';

export default class ProofPJ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: true,
    };
  }

  render() {
    const { collapse } = this.state;

    const { subject } = this.props;

    return (
      <Wrapper>
        <Header>
          <Name>
            <BusinessIcon color="#818181" size={20} />
            <span>{subject.company_name}</span>
          </Name>
          <DetailsButton
            onClick={() =>
              this.setState(state => ({ collapse: !state.collapse }))
            }
          >
            {collapse ? 'Ver mais' : 'Fechar'}
          </DetailsButton>
        </Header>
        <Details collapsed={collapse}>
          <span>
            <b>Qualificação:</b> {subject.qualification}
          </span>
          <br />
          <span>
            <b>Representante legal</b>
          </span>
          <span>
            <b>Nome:</b> {subject.name}
          </span>
          <span>
            <b>E-mail:</b> {subject.email}
          </span>
          <span>
            <b>CPF:</b> {subject.cpf || 'Não informado'}
          </span>
          <span>
            <b>IP:</b> {subject.confirmed_ip_address}
          </span>
          <br />
          <span>
            <b>Tipo de assinatura:</b>{' '}
            {subject.engine === 'standard'
              ? 'Eletrônica'
              : 'Certificado digital'}
          </span>
          <span>
            <b>Assinado em:</b>{' '}
            {subject.confirmed_at &&
              format(
                parseISO(subject.confirmed_at),
                "dd/MM/yyyy' às 'HH:mm:ss",
                {
                  locale: ptBR,
                }
              )}
          </span>
          {subject.engine === 'certificate' && (
            <>
              <br />
              {subject.certificate_name && (
                <span>
                  <b>Dados do certificado:</b>
                </span>
              )}
              {subject.certificate_name && (
                <span>
                  <b>Nome:</b> {subject.certificate_name}
                </span>
              )}
              {subject.certificate_email && (
                <span>
                  <b>Email:</b> {subject.certificate_email}
                </span>
              )}
              {subject.certificate_document && (
                <span>
                  <b>Documento:</b> {subject.certificate_document}
                </span>
              )}
              {subject.certificate_company_name && (
                <span>
                  <b>Empresa:</b> {subject.certificate_company_name}
                </span>
              )}
            </>
          )}
        </Details>
      </Wrapper>
    );
  }
}
