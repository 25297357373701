import styled from 'styled-components';

export const Info = styled.div`
  width: 35%;
  height: 35%;
  position: absolute;
  top: ${(props) => props.open ? '15%' : '-100%'};
  background-color: ${(props) => props.theme.lightBackground};
  border-radius: 6px;
  z-index: 2;
  padding: 1.5rem;
  transition: top 500ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 80vw;
    top: ${(props) => props.open ? '3vh' : '-100vh'};

    ul {
      li {
        div {
          padding-left: 1rem;
        }
      }
    }
  }
  
  h3 {
    padding: 0;
    margin: 0;
    text-align: center;
    margin-top: 0.5rem;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      div {
        width: 90%;
        padding-top: 0.4rem;

        a {
          color: ${(props) => props.theme.primaryColor};
        }
      }
    }
  }
`;

export const Icon = styled.span`
  width: 30px;
  height: 30px;
  border: 2px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
  border-radius: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const Background = styled.div`
  width: 100vw;
  height: 100%;
  position: absolute;
  background: rgba(0,0,0,0.2);
  z-index: 1;
  top: ${(props) => props.open ? '0' : '-100%'};
  transition: top 100ms;

  @media (min-width: 320px) and (max-width: 480px) {
    top: ${(props) => props.open ? '0' : '-100%'};
  }
`;
