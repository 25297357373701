import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 90%;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 3rem 0;
  border-radius: 0.5rem;
  background-color: ${props => props.theme.lightBackground};
`;

export const Header = styled.div`
  background-color: ${props => props.theme.successSecondaryColor};
  color: ${props => props.theme.successColor};
  width: 100%;
  height: 8.75rem;
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    font-size: 4rem;
    margin: 1.25rem;
  }

  > p {
    margin: 1rem;
    text-align: center;
    color: ${props => props.theme.successColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 0 0 0.5rem 0.5rem;

  > p {
    color: #818181;
    font-size: 14px;
  }
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DocumentWrapper = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  > a {
    margin-top: 0.5rem;
    text-decoration: none;
  }
`;

export const SubjectsWrapper = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
`;

export const SubjectsList = styled.ul`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  list-style: none;

  padding: 0;
  margin: 0 0 3rem 0;
`;