import React from 'react';

import { parseISO, format } from 'date-fns';

import ptBR from 'date-fns/locale/pt-BR';

import {
  Wrapper,
  Header,
  Content,
  DocumentWrapper,
  SubjectsWrapper,
  SubjectsList,
  Footer,
} from './styles';

import { Button, Link } from '../../../../components';
import { ProofPJ, ProofPF } from './components';
import { ValidDocument as IconValidDocument } from '../../../../../Icons';

const Success = ({ data, onResetValidation }) => {
  const { subjects, title, hash, insertedAt, completedAt, download } = data;

  return (
    <>
      <Wrapper success>
        <Header>
          <IconValidDocument />
          <p>
            Autenticidade de assinatura do documento reconhecida pela
            Contraktor.
          </p>
        </Header>
        <Content>
          <DocumentWrapper>
            <h3>Dados do documento: </h3>
            <span>
              <b>Nome:</b> {title}
            </span>
            <span>
              <b>Assinatura enviada em:</b>{' '}
              {insertedAt &&
                format(
                  parseISO(`${insertedAt}Z`),
                  "dd 'de' MMMM' de 'yyyy' às ' HH:mm:ss",
                  {
                    locale: ptBR,
                  }
                )}
            </span>
            <span>
              <b>Assinatura concluída em:</b>{' '}
              {completedAt &&
                format(
                  parseISO(`${completedAt}Z`),
                  "dd 'de' MMMM' de 'yyyy' às ' HH:mm:ss",
                  {
                    locale: ptBR,
                  }
                )}
            </span>
            <span>
              <b>HASH:</b> {hash}
            </span>
            <Link href={download} target="_blank">
              Baixar documento
            </Link>
          </DocumentWrapper>
          <SubjectsWrapper>
            <h3>Partes: ({subjects.length})</h3>
            <SubjectsList>
              {subjects.length !== 0 &&
                subjects.map((subject, key) =>
                  subject.company_name ? (
                    <ProofPJ subject={subject} key={key} />
                  ) : (
                    <ProofPF subject={subject} key={key} />
                  )
                )}
            </SubjectsList>
          </SubjectsWrapper>
        </Content>
        <Footer>
          <p>
            Documento assinado eletronicamente ou digitalmente conforme MP
            2.200-2/01, Art 10o, §2.
          </p>
        </Footer>
      </Wrapper>
      <Button onClick={onResetValidation}>Fazer nova verificação</Button>
    </>
  );
};

export default Success;
