import React from 'react';
import styled from 'styled-components';

import { UploadStep, ManualInputStep, ScanStep, ModalInfo } from './components';

import BlueShieldIcon from '../../assets/icons/blue-shield.svg';

const Wrapper = styled.div`
  padding: 2rem;
  min-height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const StyledIcon = styled.img`
  display: inline-block;
  height: 3rem;
  width: 3rem;
  margin: 0.5rem;
`;

const Title = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: stretch;
  align-items: center;
  h1 {
    font-size: 1.75rem;
    margin: 0;
    display: inline-block;
  }
`;

const StyledParagraph = styled.p`
  margin: 0 2rem;
  padding: 1rem;
  text-align: center;

  > span {
    font-weight: bold;
    display: block;
  }
`;

class SignatureValidator extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      uploadMode: true,
      currentStep: 'uploadStep',
      scanToken: null,
      modal: false,
    };

    this.setScanToken = this.setScanToken.bind(this);
    this.setScanResponse = this.setScanResponse.bind(this);
    this.resetValidation = this.resetValidation.bind(this);
    this.toggleMode = this.toggleMode.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  setScanToken(token) {
    this.setState({
      scanToken: token,
      currentStep: 'scanStep',
    });
  }

  setScanResponse(data) {
    this.setState({
      currentStep: 'responsesStep',
    });
  }

  resetValidation() {
    this.setState({
      currentStep: this.state.uploadMode ? 'uploadStep' : 'manualInputStep',
      scanToken: null,
    });
  }

  toggleMode() {
    this.setState({
      currentStep: !this.state.uploadMode ? 'uploadStep' : 'manualInputStep',
      uploadMode: !this.state.uploadMode,
    });
  }

  toggleModal() {
    this.setState(state => ({
      modal: !state.modal,
    }));
  }

  render() {
    const { currentStep, scanToken, modal } = this.state;

    const stepComponent = {
      uploadStep: (
        <UploadStep
          setScanToken={this.setScanToken}
          toggleMode={this.toggleMode}
          toggleModal={this.toggleModal}
        />
      ),
      manualInputStep: (
        <ManualInputStep
          setScanToken={this.setScanToken}
          toggleMode={this.toggleMode}
        />
      ),
      scanStep: (
        <ScanStep
          scanToken={scanToken}
          resetValidation={this.resetValidation}
          toggleModal={this.toggleModal}
        />
      ),
    };

    return (
      <Wrapper>
        <ModalInfo open={modal} toggleModal={this.toggleModal} />
        <Title>
          <StyledIcon src={BlueShieldIcon} />
          <h1>Verificador de autenticidade Contraktor</h1>
        </Title>
        <StyledParagraph>
          <span>Compare e verifique rapidamente se o documento foi validado via Contraktor.</span>
          A tecnologia de verificação de autenticidade da Contraktor garante
          transparência, imutabilidade e irrevogabilidade para transações de
          assinatura eletrônica ou digital.
        </StyledParagraph>
        {stepComponent[currentStep]}
      </Wrapper>
    );
  }
}

export default SignatureValidator;
