import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledButton = styled.button`
  border-radius: 0.5rem;
  background-color: ${props => props.theme.linkColor};
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: 0;
  padding: 1rem;
  margin: 1rem;
  cursor: pointer;
`;

const Button = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
