import styled from 'styled-components';

export const Subtitle = styled.h2`
  color: ${props => props.theme.linkColor};
  font-size: 1rem;
  margin: 0;
  margin-bottom: 1rem;
`;

export const TokenInput = styled.input`
  width: 80%;
  min-width: 10rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: 1px solid ${props => props.theme.lightBackgroundBorder};
  background-color: #ffffff;
  padding: 0 1rem;
`;
