import React, { Fragment } from 'react';
import styled from 'styled-components';

import Header from './Header';
import Footer from './Footer';
import SignatureValidator from '../SignatureValidator';

const PageWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index:1;
`;

const Background = styled.div`
  background-image: linear-gradient(#fff 50%, #0d0440 50%);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index:0;
`;

const ContentWrapper = styled.div`
  width: 65%;
  min-width: 26rem;
  max-width: 95%;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
`;

const Layout = () => {
  return (
    <Fragment>
      <Background />
      <PageWrapper>
        <Header/>
        <ContentWrapper>
          <SignatureValidator />
        </ContentWrapper>
        <Footer/>
      </PageWrapper>
    </Fragment>
  );
};

export default Layout;