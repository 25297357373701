import React from 'react';
import styled from 'styled-components';

const IconSvg = styled.svg`
  fill: currentColor;
  fill-rule: evenodd;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const Upload = (props) => (
  <IconSvg
    viewBox="0 0 86.8 86.8"
    height="1em"
    {...props}
  >
    <g id="noun_Upload_1515287" transform="translate(215 979.8)">
      <g id="Group_181" transform="translate(-243 -919.8)">
        <path id="Path_98" d="M32 56a4 4 0 0 0-4 4v11.8a15.8 15.8 0 0 0 15.8 15.8H99a15.8 15.8 0 0 0 15.8-15.8V59.9a4 4 0 0 0-7.9 0v11.9a7.9 7.9 0 0 1-7.9 7.9H43.8a7.9 7.9 0 0 1-8-8V60A4 4 0 0 0 32 56z" transform="translate(0 -60.8)"/>
        <path id="Path_99" d="M59.8 94.4a4 4 0 0 0 4-4V41.3l9 8.7a4 4 0 1 0 5.5-5.7L62.5 29a4 4 0 0 0-5.4 0L41.3 44a4 4 0 1 0 5.4 5.8l9.1-8.7v49.3a4 4 0 0 0 4 4z" transform="translate(11.6 -88)"/>
      </g>
    </g>
  </IconSvg>
);

export default Upload;
