import styled from 'styled-components';
import { Upload as IconUpload } from '../../../Icons';

export const Subtitle = styled.h2`
  color: ${props => props.theme.linkColor};
  font-size: 1rem;
  margin: 0;
  margin-bottom: 1rem;
`;

export const DropFile = styled.label`
  width: 90%;
  min-height: 12.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: ${props => props.theme.lightBackground};
  border: 0.25rem dashed ${props => props.theme.lightBackgroundBorder};
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`;

export const DropFileText = styled.div`
  color: ${props => props.theme.lightTextColor};
  margin: 1rem;
  text-align: center;
  p {
    margin: 0;
    font-size: 1.25rem;
  }
  strong {
    color: ${props => props.theme.linkColor};
  }
`;

export const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
`;

export const Link = styled.a`
  color: ${props => props.theme.linkColor};
  font-weight: bold;
  cursor: pointer;
`;

export const UploadIcon = styled(IconUpload)`
  font-size: 5.5rem;
  color: #d0d0d0;
`;
