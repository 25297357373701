import React from 'react';
import styled from 'styled-components';

const IconSvg = styled.svg`
  fill: currentColor;
  fill-rule: evenodd;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const CircleWithX = (props) => (
  <IconSvg
    viewBox="0 0 50.1 50.1"
    height="1em"
    {...props}
  >
    <g fill="currentColor" >
      <path d="M30.4 16l-5.3 5.5-5.4-5.4-3.6 3.6 5.4 5.4-5.4 5.3 3.6 3.6 5.3-5.4 5.4 5.4 3.6-3.6-5.4-5.3 5.4-5.4z" />
      <path d="M25 0a25 25 0 1 0 25.1 25 25 25 0 0 0-25-25zm0 45a20 20 0 1 1 20-20 20 20 0 0 1-20 20z" />
    </g>
  </IconSvg>
);

export default CircleWithX;
