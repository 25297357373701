/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from 'styled-components';

import Logo from '../Logo';

import icp from '../../assets/icp.png';
import FacebookIcon from '../../assets/icons/facebook.svg';
import LinkedinIcon from '../../assets/icons/linkedin.svg';

const Wrapper = styled.div`
  width: 60%;
  text-align: left;
  height: 12.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem auto;
`;

const Left = styled.div``;

const Right = styled.div``;

const Image = styled.img`
  width: 70px;
`;

const SocialBtnsWrapper = styled.div`
  width: 8rem;
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;

  img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
  }
`;

const Info = styled.p`
  color: #fff;
  font-size: 14px;
`;

const Footer = () => (
  <Wrapper>
    <Left>
      <Logo logoStyle="outlined" colorPrimary="#ffffff" />
      <SocialBtnsWrapper>
        <a
          href="https://www.facebook.com/contraktorx/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={FacebookIcon} alt="Facebook Icon" />
        </a>
        <a
          href="https://www.linkedin.com/company/contraktor/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LinkedinIcon} alt="Facebook Icon" />
        </a>
      </SocialBtnsWrapper>
      <Info>
        CNPJ 25.124.220/0001­-58
        <br />
        Av. Visc. de Guarapuava, 3263 - Curitiba
      </Info>
    </Left>
    <Right>
      <Image src={icp} />
    </Right>
  </Wrapper>
);

export default Footer;
