/* eslint-disable no-tabs */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';

const Outlined = ({ width, height, colorPrimary }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox={`0 0 ${width * 4.1} ${height * 4.1}`}
  >
    <g id="Layer_2_1_">
      <g id="marca_hor">
        <g className="st0">
          <path
            fill={colorPrimary}
            d="M422.8,161h6.2l16.5,36.7h-6.7l-2.9-6.3h-20.2l-2.9,6.3h-6.7L422.8,161z M433.8,186.2l-4.2-9.5c-1.6-3.6-3.6-8.6-3.6-8.6
				s-2,4.9-3.6,8.6l-4.2,9.5H433.8z"
          />
          <path
            fill={colorPrimary}
            d="M447.9,194.2l2.4-5.2c1.8,1.5,4.7,3.3,8.9,3.3c2.5,0,5.6-1,5.6-2.9c0-2-2.8-2.5-5.6-3.3c-5.3-1.5-9.9-3.1-9.9-8.6
				c0-5.5,5.6-8.4,10.5-8.4c4.2,0,7,1.1,10,2.9l-2.3,5.1c-2.5-1.4-4.9-2.1-7.6-2.1c-2.1,0-4.2,1-4.2,2.4c0,1.9,2.3,2.4,4.8,2.9
				c5.2,1.1,10.7,3.2,10.7,9c0,6.6-6.7,9-12,9C453.4,198.3,449.5,195.7,447.9,194.2z"
          />
          <path
            fill={colorPrimary}
            d="M474.7,194.2l2.4-5.2c1.8,1.5,4.7,3.3,8.9,3.3c2.5,0,5.6-1,5.6-2.9c0-2-2.8-2.5-5.6-3.3c-5.3-1.5-9.9-3.1-9.9-8.6
				c0-5.5,5.6-8.4,10.5-8.4c4.2,0,7,1.1,10,2.9l-2.3,5.1c-2.5-1.4-4.9-2.1-7.6-2.1c-2.1,0-4.2,1-4.2,2.4c0,1.9,2.3,2.4,4.8,2.9
				c5.2,1.1,10.7,3.2,10.7,9c0,6.6-6.7,9-12,9C480.2,198.3,476.3,195.7,474.7,194.2z"
          />
          <path
            fill={colorPrimary}
            d="M502.3,161.8c0-2.1,1.7-3.9,3.8-3.9c2.1,0,3.9,1.8,3.9,3.9c0,2.1-1.8,3.8-3.9,3.8C504,165.6,502.3,163.9,502.3,161.8z
				 M503,169.7h6.3v28H503V169.7z"
          />
          <path
            fill={colorPrimary}
            d="M515.6,169.7h6.3v4c2.1-3.1,5.3-4.6,9.1-4.6c7.6,0,10.1,5,10.1,12.5v16.1h-6.3v-15.3c0-4.6-1-7.3-5.7-7.3
				c-4.1,0-7.1,3.2-7.1,7.9v14.7h-6.3V169.7z"
          />
          <path
            fill={colorPrimary}
            d="M546.1,183.7c0-8.1,5.3-14.6,13-14.6c3.8,0,7.1,1.5,9.3,4.1v-3.6h6.3v28h-6.3v-3.5c-2.2,2.6-5.6,4.1-9.3,4.1
				C551.3,198.3,546.1,191.8,546.1,183.7z M568.4,183.7c0-4.9-3.3-8.6-7.8-8.6c-4.8,0-8.2,3.6-8.2,8.6c0,4.9,3.4,8.5,8.2,8.5
				C565,192.2,568.4,188.7,568.4,183.7z"
          />
          <path
            fill={colorPrimary}
            d="M582.8,188.7v-13.9h-4.1v-5.2h4.1v-5.5l6.3-1.3v6.8h8.4v5.2h-8.4v14.1c0,3,1.4,4,3,4c2.2,0,4.1-1.4,4.1-1.4l1.9,4.7
				c-1.3,0.8-3.6,2.1-6.7,2.1C585.5,198.3,582.8,195.2,582.8,188.7z"
          />
          <path
            fill={colorPrimary}
            d="M601.9,186.7v-17.1h6.3v16.1c0,4.5,2,6.4,5.9,6.4c4.4,0,6.7-3.6,6.7-7.6v-14.9h6.3v28h-5.9v-4.3c-1.7,3.1-5.6,4.9-9,4.9
				C604.6,198.3,601.9,194.1,601.9,186.7z"
          />
        </g>
        <g className="st0">
          <path fill={colorPrimary} d="M633.4,169.7h6.3v5.6c1.1-2.8,4.1-6.1,10.1-6.1v6.9c-6.2,0-10.1,2.9-10.1,10.3v11.4h-6.3V169.7z" />
        </g>
        <g className="st0">
          <path
            fill={colorPrimary}
            d="M652.8,183.7c0-8.1,5.3-14.6,13-14.6c3.8,0,7.1,1.5,9.3,4.1v-3.6h6.3v28h-6.3v-3.5c-2.2,2.6-5.6,4.1-9.3,4.1
				C658.1,198.3,652.8,191.8,652.8,183.7z M675.1,183.7c0-4.9-3.3-8.6-7.8-8.6c-4.8,0-8.2,3.6-8.2,8.6c0,4.9,3.4,8.5,8.2,8.5
				C671.8,192.2,675.1,188.7,675.1,183.7z"
          />
          <path
            fill={colorPrimary}
            d="M699.5,179.4c0-10.5,8.2-18.9,19.3-18.9c4.9,0,9.2,1.7,12.6,4.4l-3.9,4.5c-2.5-2.1-5.3-2.9-8.6-2.9
				c-7.2,0-12.5,5.9-12.5,13s5.3,13,12.5,13c3.9,0,7.3-1.8,9.5-4.5v-6.3h-6.7V176h13.1v14.3c-3.3,4.9-9.2,7.9-15.9,7.9
				C707.7,198.3,699.5,189.9,699.5,179.4z"
          />
        </g>
        <g className="st0">
          <path fill={colorPrimary} d="M741.5,169.7h6.3v5.6c1.1-2.8,4.1-6.1,10.1-6.1v6.9c-6.2,0-10.1,2.9-10.1,10.3v11.4h-6.3V169.7z" />
        </g>
        <g className="st0">
          <path
            fill={colorPrimary}
            d="M760.9,183.7c0-8.1,5.3-14.6,13-14.6c3.8,0,7.1,1.5,9.3,4.1v-3.6h6.3v28h-6.3v-3.5c-2.2,2.6-5.6,4.1-9.3,4.1
				C766.1,198.3,760.9,191.8,760.9,183.7z M783.2,183.7c0-4.9-3.3-8.6-7.8-8.6c-4.8,0-8.2,3.6-8.2,8.6c0,4.9,3.4,8.5,8.2,8.5
				C779.9,192.2,783.2,188.7,783.2,183.7z M775.6,157.3h6.5l-5.9,8.3h-5.6L775.6,157.3z"
          />
          <path
            fill={colorPrimary}
            d="M797.6,188.7v-13.9h-4.1v-5.2h4.1v-5.5l6.3-1.3v6.8h8.4v5.2h-8.4v14.1c0,3,1.4,4,3,4c2.2,0,4.1-1.4,4.1-1.4l1.9,4.7
				c-1.3,0.8-3.6,2.1-6.7,2.1C800.4,198.3,797.6,195.2,797.6,188.7z"
          />
          <path
            fill={colorPrimary}
            d="M816.4,161.8c0-2.1,1.7-3.9,3.8-3.9s3.9,1.8,3.9,3.9c0,2.1-1.8,3.8-3.9,3.8S816.4,163.9,816.4,161.8z M817.1,169.7h6.3
				v28h-6.3V169.7z"
          />
          <path
            fill={colorPrimary}
            d="M828.2,194.2l2.4-5.2c1.8,1.5,4.7,3.3,8.9,3.3c2.5,0,5.6-1,5.6-2.9c0-2-2.8-2.5-5.6-3.3c-5.3-1.5-9.9-3.1-9.9-8.6
				c0-5.5,5.6-8.4,10.5-8.4c4.2,0,7,1.1,10,2.9l-2.3,5.1c-2.5-1.4-4.9-2.1-7.6-2.1c-2.1,0-4.2,1-4.2,2.4c0,1.9,2.3,2.4,4.8,2.9
				c5.2,1.1,10.7,3.2,10.7,9c0,6.6-6.7,9-12,9C833.7,198.3,829.8,195.7,828.2,194.2z"
          />
        </g>
        <path
          fill={colorPrimary}
          d="M472,111.1c-5.9,6.1-14.1,9.5-22.7,9.4c-11.4,0.1-21.9-6.5-26.7-16.8c-2.2-4.6-3.3-9.5-3.2-14.6c0-5.7,1.5-11.2,4.3-16.1
			c5.5-9.3,15.5-15,26.4-14.8c7-0.2,13.9,2.2,19.3,6.8c3.3-3.6,10-10.8,12.9-14.2c-8.6-7.4-20.4-11.7-33.4-11.7
			c-19.5,0-35.2,9.4-44,23.5c-4.9,8-7.5,17.2-7.4,26.6c-0.1,9.2,2.3,18.3,7,26.2c8.6,14.6,24.6,24.3,44.4,24.3
			c14.5,0,26.8-5.1,36.1-13.7C481.9,122.6,476.2,115.6,472,111.1z"
        />
        <path
          fill={colorPrimary}
          d="M490.5,100.3c0-21.8,17.7-39.1,40.1-39.1c22.5,0,40.3,17.3,40.3,39.1s-17.7,39.1-40.3,39.1
			C508.3,139.4,490.5,122.1,490.5,100.3z M550.4,100.3c0-11.7-8.4-20.4-19.7-20.4S511,88.6,511,100.3s8.4,20.4,19.7,20.4
			S550.4,112.2,550.4,100.3z"
        />
        <path
          fill={colorPrimary}
          d="M623,61.1c-5.8-0.1-11.5,1.5-16.4,4.5c-2.9,1.9-5.4,4.2-7.3,7V62.3h-19.7v75.4h20.2v-38c0-11.4,6.8-19.5,16.8-19.5
			c10.2,0,13.1,5.8,13.1,16.8v40.7h20.1V94.5C649.7,73.9,642.8,61.1,623,61.1z"
        />
        <path
          fill={colorPrimary}
          d="M707,118.4c-2.9,1.9-6.2,3-9.6,3.2c-3.6,0-6.8-2.1-6.8-8.6V78.6h20.7V62.4h-20.7V38.3h-20.1v24.1h-11v16.2h11v34.7
			c0,17,8.1,25.7,23.2,25.7c6.8,0,13.4-2,19.1-5.7L707,118.4z"
        />
        <path fill={colorPrimary} d="M745.1,73.2V62.4h-19.8v75.1h20.1v-28.9c0-17.9,8.6-25.9,26-25.9V62.4C757.9,62.4,752.6,65.3,745.1,73.2z" />
        <path fill={colorPrimary} d="M1112.6,73.2V62.4h-19.8v75.1h20.1v-28.9c0-17.9,8.6-25.9,26-25.9V62.4C1125.4,62.4,1120.1,65.3,1112.6,73.2z" />
        <path
          fill={colorPrimary}
          d="M925,113.2l-14.6-18l18-20.1l11.4-12.7h-24.5l-25.9,31.2V38.3h-20.1v99.2h20.1V119l8.6-10.2l15.3,19.5l7.3,9.2h24.2
			L925,113.2z"
        />
        <path
          fill={colorPrimary}
          d="M1003.7,100.4c0-21.8,17.7-39.1,40.1-39.1s40.3,17.3,40.3,39.1s-17.7,39.1-40.3,39.1S1003.7,122.2,1003.7,100.4z
			 M1063.6,100.4c0-11.7-8.4-20.4-19.7-20.4s-19.7,8.7-19.7,20.4c0,11.9,8.4,20.4,19.7,20.4S1063.6,112.2,1063.6,100.4L1063.6,100.4
			z"
        />
        <path
          fill={colorPrimary}
          d="M994.2,118.4c-2.8,1.9-6.2,3-9.6,3.2c-3.6,0-6.8-2.1-6.8-8.6V78.5h20.7V62.3h-20.7v-24h-20.1v24h-11v16.2h11v34.7
			c0,17,8.1,25.7,23.1,25.7c6.8,0,13.4-2,19.1-5.7L994.2,118.4z"
        />
        <path
          fill={colorPrimary}
          d="M778.3,99.9c0-21.6,13.2-39,33.9-39c8.7,0,18,3.4,23.4,10.7v-9.2h19.7v75.1h-19.7v-9c-5.2,7.2-14.7,10.5-23.4,10.5
			C791.6,139,778.3,121.6,778.3,99.9z M835.2,99.9c0-11.7-7.5-20.4-17.9-20.4c-10.8,0-18.6,8.7-18.6,20.4c0,11.9,7.8,20.4,18.6,20.4
			C827.7,120.4,835.2,112,835.2,99.9z"
        />
        <path
          fill={colorPrimary}
          d="M137.3,147.7C110.6,168.6,72,164,51,137.3S34.8,72,61.5,51c22.3-17.5,53.6-17.5,75.8,0V7.5C86.5-13.4,28.4,10.8,7.5,61.5
			s3.3,108.8,54,129.7c24.2,10,51.5,10,75.7,0V147.7z"
        />
        <path fill={colorPrimary} d="M194.7,4v82.3l-29.9,29.8V4H194.7 M198.7,0h-37.9v125.7L198.7,88V0z" />
        <polygon fill={colorPrimary} points="239.5,99.4 186.3,99.4 212.9,126 161,177.9 161,124.5 247.3,38.3 300.6,38.3 		" />
        <path fill={colorPrimary} d="M238,103.4l90.3,90.3h-42.1l-90.3-90.3H238 M239.7,99.4h-53.4l98.3,98.3H338L239.7,99.4L239.7,99.4z" />
      </g>
    </g>
  </svg>
);

Outlined.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  colorPrimary: PropTypes.string.isRequired,
};

export default Outlined;
