import React from 'react';
import { Wrapper } from './styles';

import { Button, Link } from '../../../../components';

import {
  InvalidDocument as IconInvalidDocument,
  CircleWithX as IconCircleWithX,
} from '../../../../../Icons';

const Erro = ({ error, onResetValidation, toggleModal }) => {
  const status = error.response && error.response.status;

  let errorMessage = (
    <>
      <section>
        <IconCircleWithX />
        <p>Um erro inesperado ocorreu</p>
      </section>
      <p>
        Ocorreu um erro durante a validação deste documento. Por favor, tente
        novamente.
      </p>
    </>
  );

  if (status === 404) {
    errorMessage = (
      <>
        <section>
          <IconInvalidDocument />
          <p>
            Autenticidade de assinatura do documento não reconhecida pela
            Contraktor.
          </p>
        </section>
        <p>
          O documento que você enviou não teve a assinatura eletrônica
          autenticada pela Contraktor. Verifique se enviou o documento correto
          ou se o processo de assinaturas já foi concluído.
        </p>
      </>
    );
  }

  return (
    <>
      <Wrapper error>{errorMessage}</Wrapper>
      <Link onClick={() => toggleModal()}>Precisa de ajuda?</Link>
      <br />
      <Button onClick={onResetValidation}>Fazer nova verificação</Button>
    </>
  );
};

export default Erro;
