import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLink = styled.a`
  color: ${props => props.theme.linkColor};
  font-weight: bold;
  cursor: pointer;
`;

const Link = ({ children, ...props }) => {
  return <StyledLink {...props}>{children}</StyledLink>;
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Link;
