import React from 'react';
import PropTypes from 'prop-types';
import { sha256 } from 'js-sha256';

import {
  DropFile,
  FileInput,
  Subtitle,
  DropFileText,
  Link,
  UploadIcon,
} from './styles';

class UploadStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleFileSelect = this.handleFileSelect.bind(this);
  }

  handleFileSelect(event) {
    const { setScanToken } = this.props;
    let file = event.target.files[0];
    let fileHash = null;

    if (file) {
      let reader = new FileReader();
      reader.onload = function(e) {
        const content = e.target.result;
        fileHash = sha256(content);
        setScanToken(fileHash);
      };

      reader.readAsArrayBuffer(file);
    }
  }

  render() {
    const { toggleMode, toggleModal } = this.props;
    return (
      <>
        <Subtitle>
          Para verificar a autenticidade, siga as instruções abaixo:
        </Subtitle>
        <DropFile>
          <UploadIcon />
          <DropFileText>
            <p>
              Solte seu arquivo aqui ou{' '}
              <strong>clique para fazer upload</strong>
            </p>
            <span>Permitido download de 1 arquivo por vez</span>
          </DropFileText>
          <FileInput
            type="file"
            name="contractFile"
            onChange={this.handleFileSelect}
            accept=".pdf"
          />
        </DropFile>
        <Link onClick={toggleMode}>
          Prefiro informar o ID ou Hash SHA256 do arquivo
        </Link>
        <br />
        <Link onClick={toggleModal}>
          Precisa de ajuda?
        </Link>
      </>
    );
  }
}

UploadStep.propTypes = {
  setScanToken: PropTypes.func.isRequired,
  toggleMode: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default UploadStep;
