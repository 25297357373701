import React from 'react';
import styled from 'styled-components';

const IconSvg = styled.svg`
  fill: currentColor;
  fill-rule: evenodd;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const ValidDocument = (props) => (
  <IconSvg
    viewBox="0 0 64.5 75.9"
    height="1em"
    {...props}
  >
    <g id="Group_208"  transform="translate(-986.7 -782.6)">
      <path id="Path_116" d="M999.7 818.9h30.7a2.2 2.2 0 1 0 0-4.4h-30.7a2.2 2.2 0 0 0 0 4.4z"  transform="translate(-1.3 -3.8)"/>
      <path id="Path_117" d="M1040.9 824.7v-26.1a2.1 2.1 0 0 0 0-.5 1.9 1.9 0 0 0-.1-.2v-.2a2 2 0 0 0-.2-.2v-.1a2.1 2.1 0 0 0-.3-.3l-13.6-13.8a2.2 2.2 0 0 0-1.2-.6 2.3 2.3 0 0 0-.4 0h-32.5a5.9 5.9 0 0 0-4.2 1.4 5.7 5.7 0 0 0-1.7 4.4V843a6.7 6.7 0 0 0 6.7 6.2h24.5a17.7 17.7 0 1 0 23-24.6zm-13.6-34.5l6.1 6.2h-6zm-33.9 54.7a2.2 2.2 0 0 1-2.3-2v-54.4a1.8 1.8 0 0 1 .3-1.2 1.4 1.4 0 0 1 .9-.2H1022.9v11.4a2.2 2.2 0 0 0 2.2 2.2h11.4v22.6a17.7 17.7 0 0 0-3.1-.3 18 18 0 0 0-2.1.1v-.1a2.2 2.2 0 0 0-2.2-2.2h-30.7a2.2 2.2 0 0 0 0 4.4h26.4a17.9 17.9 0 0 0-6.2 5.7h-20.2a2.2 2.2 0 0 0 0 4.4h18a17.4 17.4 0 0 0-.2 9.6zm40 9.2a13.3 13.3 0 1 1 13.4-13.3 13.4 13.4 0 0 1-13.4 13.3z" />
      <path id="Path_118" d="M1036.5 849.4l-4.1-4-2.3 2.4 6.4 6.2 10.6-10-2.3-2.4z"  transform="translate(-5.2 -7)"/>
    </g>
  </IconSvg>
);

export default ValidDocument;
