import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 90%;
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  border-radius: 0.5rem;
  ${(props) => (props.error || props.success) ? `background-color: ${props.theme.lightBackground}` : ''}

  section {
    ${(props) => (props.error) ? `background-color: ${props.theme.errorSecondaryColor}; color: ${props.theme.errorColor};` : ''}
    ${(props) => (props.success) ? `background-color: ${props.theme.successSecondaryColor}; color: ${props.theme.successColor};` : ''}
    width: 100%;
    height: 8.75rem;
    border-radius: 0.5rem 0.5rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 4rem;
      margin: 1.25rem;
    }
  }
  & > p {
    margin: 1rem;
    text-align: center;
    color: ${(props) => props.theme.lightTextColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;