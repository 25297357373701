import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module'
import App from './App';

import './index.css';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
}

if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs)
}


ReactDOM.render(<App />, document.getElementById('root'));
