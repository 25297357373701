import React from 'react';
import PropTypes from 'prop-types';

import { Subtitle, TokenInput } from './styles';
import { Button, Link } from '../../components';

class ManualInputStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
    };
    this.onTokenSubmit = this.onTokenSubmit.bind(this);
    this.onTokenChange = this.onTokenChange.bind(this);
  }

  onTokenSubmit() {
    // Removes the # character if given as document ID
    const scanToken = this.state.inputValue.replace('#', '');
    this.props.setScanToken(scanToken);
  }

  onTokenChange(e) {
    this.setState({
      inputValue: e.target.value.replace(/ /g,''),
    });
  }

  render() {
    const { toggleMode } = this.props;
    const { inputValue } = this.state;
    return (
      <>
        <Subtitle>
          ID ou Hash SHA256 do documento assinado via Contraktor:
        </Subtitle>
        <TokenInput
          name="tokenInput"
          onChange={this.onTokenChange}
          value={inputValue}
        />
        <Button onClick={this.onTokenSubmit}>Verificar autenticidade</Button>
        <Link onClick={toggleMode}>Prefiro fazer o upload do arquivo</Link>
      </>
    );
  }
}

ManualInputStep.propTypes = {
  setScanToken: PropTypes.func.isRequired,
  toggleMode: PropTypes.func.isRequired,
};

export default ManualInputStep;
