import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 90%;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 3rem 0;

  border-radius: 0.5rem;
  background-color: ${props => props.theme.lightBackground};

  section {
    background-color: ${props => props.theme.errorSecondaryColor};
    color: ${props => props.theme.errorColor};

    width: 100%;
    height: 8.75rem;
    border-radius: 0.5rem 0.5rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 4rem;
      margin: 1.25rem;
    }
  }

  & > p {
    margin: 1rem;
    text-align: center;
    color: ${props => props.theme.lightTextColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
