import React from 'react';
import styled from 'styled-components';

const IconSvg = styled.svg`
  fill: currentColor;
  fill-rule: evenodd;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const InvalidDocument = (props) => (
  <IconSvg
    viewBox="0 0 63.6 74.9"
    height="1em"
    {...props}
  >
    <g id="Group_210" data-name="Group 210" transform="translate(-866 -782.6)">
      <path d="M879 818.8h30.3a2.2 2.2 0 0 0 0-4.3H879a2.2 2.2 0 0 0 0 4.3z" transform="translate(-1.4 -4.2)" />
      <path d="M919.5 824.1v-25.7a2.2 2.2 0 0 0 0-.5 2 2 0 0 0-.1-.2v-.2a2 2 0 0 0-.2-.2v-.1a2.1 2.1 0 0 0-.3-.3l-13.4-13.6a2.2 2.2 0 0 0-1.1-.6 2.2 2.2 0 0 0-.5 0h-32a5.8 5.8 0 0 0-4.2 1.4 5.6 5.6 0 0 0-1.7 4.3v53.9a6.6 6.6 0 0 0 6.7 6.1h24a17.5 17.5 0 1 0 22.8-24.3zm-13.4-34l6 6h-6zm-33.4 54a2.2 2.2 0 0 1-2.4-2v-53.7a1.7 1.7 0 0 1 .3-1.1 1.4 1.4 0 0 1 1-.3h30.1v11.3a2.2 2.2 0 0 0 2.2 2.2h11.3v22.2a17.5 17.5 0 0 0-3-.2 17.7 17.7 0 0 0-2.2.1v-.1a2.2 2.2 0 0 0-2.1-2.2h-30.3a2.2 2.2 0 1 0 0 4.3h26a17.6 17.6 0 0 0-6 5.7h-20a2.2 2.2 0 1 0 0 4.3h17.8a17.2 17.2 0 0 0-.3 9.5zm39.4 9a13.2 13.2 0 1 1 13.2-13.1A13.2 13.2 0 0 1 912 853z" />
      <path d="M921.6 841.6l-3.5 3.4-3.4-3.4-2.7 2.6 3.5 3.5-3.5 3.5 2.7 2.6 3.4-3.4 3.5 3.4 2.7-2.6-3.5-3.5 3.5-3.5z" transform="translate(-6 -7.7)" />
    </g>
  </IconSvg>
);

export default InvalidDocument;
