export default {
  primaryColor: '#0d0440',
  infoColor: '#e5f2fa',
  errorColor: '#fa1a4d',
  errorSecondaryColor: '#fce9e8',
  alertColor: '#ffc217',
  alertSecondaryColor: '#fff8e7',
  successColor: '#04ceb7',
  successSecondaryColor: '#e5f9e5',
  pendingColor: '#fd9828',

  linkColor: '#346BF7',
  lightBackground: '#fafafa',
  lightBackgroundBorder: '#e0e0e0',
  lightTextColor: '#818181',
};
