import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Loading, Success, Error } from './components';

class ScanStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      subjects: [],
      title: '',
      hash: '',
      insertedAt: '',
      completedAt: '',
      download: '',
    };

    this.scanDocument = this.scanDocument.bind(this);
  }

  componentDidMount() {
    this.scanDocument();
  }

  async scanDocument() {
    const { scanToken } = this.props;

    this.setState({
      loading: true,
    });

    await axios
      .get(`https://api-validator.contraktor.com.br/api/v1/scan/${scanToken}`)
      .then((res) => {
        const { data } = res.data;

        this.setState({
          subjects: data.subjects,
          title: data.document_title,
          hash: data.document_hash,
          completedAt: data.document_proof_completed_at,
          insertedAt: data.document_proof_inserted_at,
          download: data.document_signed_preview,
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e,
        });
      });
  }

  render() {
    const {
      loading,
      error,
      subjects,
      title,
      hash,
      insertedAt,
      completedAt,
      download,
    } = this.state;

    const { resetValidation, toggleModal } = this.props;

    if (loading) {
      return <Loading onResetValidation={resetValidation} />;
    }

    if (error) {
      return (
        <Error
          error={error}
          onResetValidation={resetValidation}
          toggleModal={toggleModal}
        />
      );
    }

    return (
      <Success
        data={{
          subjects,
          title,
          hash,
          insertedAt,
          completedAt,
          download,
        }}
        onResetValidation={resetValidation}
      />
    );
  }
}

ScanStep.propTypes = {
  scanToken: PropTypes.string,
  resetValidation: PropTypes.func.isRequired,
};

export default ScanStep;
