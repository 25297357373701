import React, { Component } from 'react';
import './App.css';
import { ThemeProvider } from 'styled-components';

import theme from './theme';
import Layout from './components/Layout';

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Layout />
      </ThemeProvider>
    );
  }
}

export default App;
